<div>
    <div class="header d-flex justify-content-between">
        <div id="titleContainer" #titleContainer class="lwFlexColumnContainer">
            <img id="lwLogoImg" #lwLogoImg src="/assets/images/img-global-loftware-logo.webp">
            <span id="migrationTitleText" #migrationTitleText>LPS Migration Portal</span>
        </div>
        <div>
            <button id="signOut" type="button" class="lwInterfaceButton" (click)="logout()">Sign out</button>
        </div>
    </div>
    <div class="page-header-bar"></div>
</div>