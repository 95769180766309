import { InjectionToken } from '@angular/core';
import { environment } from '../environments/environment'

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export interface AppConfig {
    serverUrl: string,
    baseHref: string,
    endpoint: string,
    production: boolean,
    cognitoClientId: string,
    cognitoUrl: string,
    openIdPlatformUrl: string
}

export const GLM_MIGRATE_CLIENT_CONFIG: AppConfig = {
    serverUrl: environment.serverUrl,
    baseHref: environment.baseHref,
    endpoint: environment.endpoint,
    production: environment.production,
    cognitoClientId: environment.cognitoClientId,
    cognitoUrl: environment.cognitoUrl,
    openIdPlatformUrl: environment.openIdPlatformUrl
}