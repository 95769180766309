<h1 mat-dialog-title>Warning</h1>
<div mat-dialog-content>
    <div #warningMessageDiv>
        <div id="warningMessage">Are you sure you want to delete this file?</div> 
        <div id="fileName" class="text-center mt-2 mb-4">{{data.fileName}}</div>
        <div id="confirmMessage">To confirm, click the Delete button.</div>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-raised-button mat-dialog-close (click)="onDelete()">Delete</button>
    <button mat-raised-button mat-dialog-close (click)="onCancel()">Cancel</button>  
</div>