import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable()
export class CanActivateAuthGuard implements CanActivate {

    constructor(private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route.queryParams['debug'] || this.authService.isLoggedIn()) {
            return true;
        } else {
            this.authService.redirectToAuthProvider();
            return false;
        }
    }

}
