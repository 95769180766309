import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';



interface DeleteWarningDialogData {
  fileName: string
}

@Component({
  selector: 'delete-warning-dialog',
  templateUrl: './delete-warning-dialog.component.html',
  styleUrls: ['./delete-warning-dialog.component.css']
})
export class DeleteWarningDialogComponent {

  constructor(public dialogRef: MatDialogRef<DeleteWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteWarningDialogData) {
     }


  onDelete() {
    this.dialogRef.close('delete');
  }


  // Send the cancel message if the cancel button is closed
  onCancel() {
    this.dialogRef.close('cancel');
  }


}
