<header></header>
<div class="help-message-block">
    <div class="message-heading">Convert LPS artifacts into Spectrum objects</div>
    <div class="help-message"> Click “Learn more” for how to use the LPS Migration Portal, how to prepare for a
        migration and what to expect with converted output.
    </div>
    <div class="help-button">
        <a id="learnMore" class="lwInterfaceButton" href="https://docs.loftware.com/LPSMigrationPortal/Default.htm"
            target="_blank" role="button">Learn more</a>
    </div>
</div>
<div class="page-content">
    <div class="card card-body shadow bg-white rounded px-5">
        <div class="stepTitle">
            Welcome to the LPS Migration Portal. You can use this portal to convert your LPS artifacts
            (labels, images, device configurations, etc.) into Spectrum (.ser) formats.
        </div>

        <div class="mt-3">
            <p>Here is a quick summary of the steps that have to be completed to convert LPS artifacts into Spectrum
                formats.</p>
            <p>This portal will guide you through these steps:</p>
        </div>

        <div class="mt-3">
            <p>
                <strong>Step 1 - </strong>
                Download the GetLPSArtifacts script
            </p>
            <p>
                <strong>Step 2 - </strong>
                Run the GetLPSArtifacts script on your production LPS servers
            </p>
            <p>
                <strong>Step 3 - </strong>
                Upload the GetLPSArtifact zip files produced by the GetLPSArtifacts script for conversion
            </p>
            <p>
                <strong>Step 4 - </strong>
                Submit uploaded GetLPSArtifact zip files for conversion
            </p>
            <p>
                <strong>Step 5 - </strong>
                Download the converted files produced once the conversion process has completed
            </p>
            <p>
                <strong>Step 6 - </strong>
                Use the Spectrum Transfer Utility to import the converted artifacts into your Spectrum system
            </p>
        </div>

        <div class="mt-3">
            <p>
                Instructions on how to complete these steps can be found here:
                <a href="https://docs.loftware.com/LPSMigrationPortal/Default.htm" target="_blank">
                    LPS Migration Portal Help
                </a>
            </p>
        </div>

        <div>
            <label class="doNotShowMessage">
                <input id="doNotShowAgain" type="checkbox" (change)="doNotShowAgainClicked($event)"
                    [(ngModel)]="doNotShowWelcomePage" />
                Do not show this page again
            </label>
        </div>

        <div class="mx-auto mt-4" width="200px">
            <button type="button" class="lwInterfaceButton" style="width: 350px" (click)="startArtifactConversion()">
                Start Artifact Conversion
            </button>
        </div>
    </div>
</div>