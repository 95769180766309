import { InjectionToken } from '@angular/core';

export const AUTH_CONFIG = new InjectionToken<AuthConfig>('auth.config');

export interface AuthConfig {
    secureToken: string,
    sessionId: string,
    authUserName: string,
    s3BucketName: string
}

export const USER_AUTH_CONFIG: AuthConfig = {
    secureToken: '',
    sessionId: '',
    authUserName: '',
    s3BucketName: 'glm-migration' // This should be updated with the user's bucket name, which we'll hopefully get from authentication.
}