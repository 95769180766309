import { Inject, Injectable } from "@angular/core";
import { AUTH_CONFIG, AuthConfig } from "../auth.config";
import { APP_CONFIG, AppConfig } from "../app.config";

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
        @Inject(APP_CONFIG) private appConfig: AppConfig
    ) { }

    public isLoggedIn(): boolean {
        return !!this.authConfig.secureToken
    }

    public clearCredentials(): void {
        this.authConfig.secureToken = '';
        this.authConfig.sessionId = '';
        this.authConfig.authUserName = '';
    }

    public redirectToAuthProvider(): void {
        const targetUrl = encodeURIComponent(window.location.href);
        window.open(`${this.appConfig.cognitoUrl}/oauth2/authorize?client_id=${this.appConfig.cognitoClientId}&response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin+&redirect_uri=${targetUrl}wait`, '_self');
    }

    public logout(): void {
        const migrationPortalUrl = window.location.origin;
        const cognitoLogoutPath = `${this.appConfig.cognitoUrl}/logout?client_id=${this.appConfig.cognitoClientId}&logout_uri=${migrationPortalUrl}`;
        const cognitoLogoutUrl = encodeURIComponent(cognitoLogoutPath);
        window.open(`${this.appConfig.openIdPlatformUrl}/logout?post_logout_redirect_uri=${cognitoLogoutUrl}`, '_self'); 
    }
}