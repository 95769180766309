import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { AppConfig, APP_CONFIG } from '../app.config';
import { AuthConfig, AUTH_CONFIG } from '../auth.config';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})

export class RemoteGateway {

    public static readonly SECURE_TOKEN_KEY = 'Authorization';

    private _serverCalled = new Subject<boolean>();

    constructor(
        @Inject(APP_CONFIG) private appConfig: AppConfig,
        @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
        private authService: AuthService,
        private http: HttpClient) { }

    public get serverCalled(): Observable<boolean> { return this._serverCalled.asObservable().pipe(filter(val => val)); }

    private buildHeader(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();

        // If there is a token then append that token to the header
        if (this.authConfig.secureToken) { headers = headers.append(RemoteGateway.SECURE_TOKEN_KEY, 'Bearer ' + this.authConfig.secureToken); }

        return headers;
    }

    public doGet(urlPart: string, responseType?: string, additionalHeaders?: any): Observable<any> {
        const targetUrl: string = this.appConfig.serverUrl + this.appConfig.baseHref + this.appConfig.endpoint + '/' + urlPart;

        const requestOptions: any = { headers: this.buildHeader(), withCredentials: true };
        if (responseType) {
            requestOptions['responseType'] = responseType;
        }
        if (additionalHeaders && requestOptions['headers']) {
            for (const key in additionalHeaders) {
                if (Object.prototype.hasOwnProperty.call(additionalHeaders, key)) {
                    const value = additionalHeaders[key];
                    requestOptions['headers'] = requestOptions['headers'].append(key, value);
                }
            }
        }
        return this.http.get(targetUrl, requestOptions)
                .pipe(catchError(this.generateErrorHandler()));
    }

    public doPost(urlPart: string, jsonBody: any): Observable<any> {
        this._serverCalled.next(true);

        let headers: HttpHeaders = this.buildHeader();

        const jsonUrl: string = this.appConfig.serverUrl + this.appConfig.baseHref + this.appConfig.endpoint + '/' + urlPart;
        return this.http.post<any>(jsonUrl, jsonBody, { headers: headers, withCredentials: true, observe: 'response' as 'response' })
            .pipe(map(
                jsonResponse => this.jsonResponseHandler(jsonResponse)),
                catchError(this.generateErrorHandler()));
    }

    public doPut(urlPart: string, jsonBody: any): Observable<any> {
        this._serverCalled.next(true);

        let headers: HttpHeaders = this.buildHeader();

        const jsonUrl: string = this.appConfig.serverUrl + this.appConfig.baseHref + this.appConfig.endpoint + '/' + urlPart;
        return this.http.put<any>(jsonUrl, jsonBody, { headers: headers, withCredentials: true, observe: 'response' as 'response' })
            .pipe(map(
                jsonResponse => this.jsonResponseHandler(jsonResponse)),
                catchError(this.generateErrorHandler()));
    }


    public doDelete(urlPart: string): Observable<any> {
        this._serverCalled.next(true);

        let headers: HttpHeaders = this.buildHeader();

        const jsonUrl: string = this.appConfig.serverUrl + this.appConfig.baseHref + this.appConfig.endpoint + '/files/' + urlPart;
        return this.http.delete<any>(jsonUrl, { headers: headers, withCredentials: true, observe: 'response' as 'response' })
            .pipe(map(
                jsonResponse => this.jsonResponseHandler(jsonResponse)),
                catchError(this.generateErrorHandler()));
    }
    private jsonResponseHandler(response: HttpResponse<any>): any {
        if (response) {
            if (response.headers.has(RemoteGateway.SECURE_TOKEN_KEY.toLowerCase())) {
                const localSecureToken: string | null = response.headers.get(RemoteGateway.SECURE_TOKEN_KEY.toLowerCase());
                if (localSecureToken !== null && localSecureToken !== undefined && localSecureToken.length > 0) {
                    if (this.authConfig.secureToken.length === 0) {
                        this.authConfig.secureToken = localSecureToken;
                    }
                }
            }

            return response['body']
        }
    }

    private generateErrorHandler(): (err: HttpErrorResponse) => Observable<any> {
        return (err: HttpErrorResponse) => {
            const returnValue: any = this.generateErrorResponse(err);
            return of<any>(returnValue);
        };
    }

    private generateErrorResponse(err: HttpErrorResponse): any {
        if (err.status === 401) {
            // If the token is not authorized, then redirect the user to the login page
            this.authService.logout();
        }
        return err;
    }

}
