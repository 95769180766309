<div id="titleContainer" #titleContainer class="lwFlexColumnContainer">
      <img id="lwLogoImg" #lwLogoImg src="/assets/images/img-global-loftware-logo.webp">
      <span id="migrationTitleText" #migrationTitleText>LPS Migration Portal</span>
</div>
<div class="page-header-bar"></div>
<div class="lw-view-window">
      <div class="wait-message d-flex justify-content-center align-items-center">
            <div id="userProcessingText">Please wait, you will be redirected after the user has been processed.</div>
      </div>
      <app-spinner></app-spinner>
</div>