import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateAuthGuard } from './can-activate.authguard';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { PostSignupPageComponent } from './pages/post-signup-page/post-signup-page.component';
import { MigrationPageComponent } from './pages/migration-page/migration-page.component';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';

const routes: Routes = [
  {
    path: '', children: [
      { path: '', component: WelcomePageComponent, canActivate: [CanActivateAuthGuard] },
      { path: 'migrate',component: MigrationPageComponent, canActivate: [CanActivateAuthGuard]  },
      { path: 'wait', component: PostSignupPageComponent },
      { path: 'login', component: LoginPageComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
