
import { Inject, Injectable } from "@angular/core";
import { Observable, of, Subject } from 'rxjs';
import { RemoteGateway } from "./remote-gateway";
import { AUTH_CONFIG, AuthConfig } from "../auth.config";
import { MigrationFile } from "../models/migration-file";
interface MigrationParameters {
    bucket: string;
    filename: string;
    email: string;
    migrationType: 'LPS' | 'RTF';
    targetVersion?: string;
    subProject?: string;
    output?: 'ser' | 'nlbl';
    migrationArgs?: string;
}
@Injectable({
    providedIn: 'root',
})
export class MigrationService {
    constructor(
        private remoteGateway: RemoteGateway,
        @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
    ) { }
    deleteFile(file:MigrationFile){
        if (this.authConfig.secureToken) {
            return this.remoteGateway.doDelete(file.filename);
        } else {
            return of({});
        }
    }
    getFileList(): Observable<MigrationFile[]> {

        // Users' Files are found at the /files endpoint
        if (this.authConfig.secureToken) {
            return this.remoteGateway.doGet('files', 'json');
        } else {
            const files = [
                { filename: 'NAWestCoast_Labels_20230502_migrated.zip', status: 'Converted', isZebra: 'true',  createDate: '2023-09-12T20:30:24Z', fileSize: 1000000 },
                { filename: 'NAEastCoast_Labels_123_migrated.zip', status: 'In Progress', isZebra: 'false', statusDetail: "Retrieving LPS Artifacts", createDate: '2023-09-12T20:30:24Z', fileSize: 1300000 },
                { filename: 'EMEA_Labels_20230503.zip', status: 'Uploaded', isZebra: 'false', createDate: '2023-06-12T20:34:14Z', fileSize: 2000110 },
                { filename: 'APAC_Labels_20230507.zip', status: 'Uploaded', isZebra: 'false', createDate: '2023-09-12T21:30:24Z', fileSize: 900500 },
                { filename: 'Tunac_20240333.zip', status: 'Uploaded', isZebra: 'false', createDate: '2024-10-03T23:23:23Z', fileSize: 7830000 },
                { filename: 'Dad1_20240111.zip', status: 'Uploaded', isZebra: 'false', createDate: '2024-01-13T11:04:18Z', fileSize: 1040550 },
                { filename: 'NAWestCoast_Labels_20230504_errors.txt', status: 'Converted', isZebra: 'false', createDate: '2023-02-11T10:10:14Z', fileSize: 5070000 },
                { filename: 'NAEastCoast_Labels_20230506_migrated.zip', status: 'In Progress', isZebra: 'false', createDate: '2023-09-12T20:30:24Z', fileSize: 3466 },
                { filename: 'Test_20230504_migrated.zip', status: 'In Progress', isZebra: 'false', statusDetail: "Starting", createDate: '2024-03-08T22:29:04Z', fileSize: 163466 },
                { filename: 'EMEA_Labels_20230501_migrated.zip', status: 'Converted', isZebra: 'false',  createDate: '2024-05-22T02:31:34Z', fileSize: 5200 },
            ] as MigrationFile[];

            return of(files);
        }

    }

    beginMigration(migrationParameters: MigrationParameters): Observable<any> {
        if (this.authConfig.secureToken) {
            // beginMigration only needs tos end filename, output, and email
            return this.remoteGateway.doPost('beginMigration', migrationParameters)
        } else {
            return of('good')
        }
    }

    checkMigrationStatus(executionArn: string) {
        if (this.authConfig.secureToken) {
            return this.remoteGateway.doGet('status/' + encodeURIComponent(executionArn));
        } else {
            return of('Success')
        }
    }


    compare(filename: string): Observable<any> {
        const params = {
            'filename': filename
        };
        if (this.authConfig.secureToken) {
            return this.remoteGateway.doPost('compare', params)
        } else {
            return of('good')
        }
    }


    getDownloadFileLink(filename: string) {
        if (this.authConfig.secureToken) {
            // Download file is found at /files/{filename} endpoint
            return this.remoteGateway.doGet('files/' + filename, 'json');
        } else {
            return of('https://glm-migration.s3.amazonaws.com/Loftware/rtfTest.zip?X-Amz-Security-Token=eyJraWQiOiJDOFRmTGlDMVB4c1d6eDV4Sk9GUGU2eWhQdFRKcHlcL050Q052ckE4SXU4RT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIwY2M4MWRkOS1lMWJiLTRiMmQtYjMyMC0yMTMwOTdhZmE4OTAiLCJjb2duaXRvOmdyb3VwcyI6WyJNaWdyYXRpb25Vc2VycyJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9IcDF5eVgyVW0iLCJ2ZXJzaW9uIjoyLCJjbGllbnRfaWQiOiIyZGlsdWg0dm9sajNrNm9laHJmMXVtYzR2OCIsIm9yaWdpbl9qdGkiOiI4NDVhNzdjZC1hNzdjLTRhM2EtYjU1OC1lMTkwZGNkNjQyOTMiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNjk0NDM2NDg2LCJleHAiOjE2OTQ0NDAwOTYsImlhdCI6MTY5NDQzNjQ5NiwianRpIjoiZjQxNGU4YWQtYzZhNi00N2ZhLTk3OGMtMWE5MWZhZThjNzIwIiwidXNlcm5hbWUiOiJsb2Z0d2FyZWlkZW50aXR5c3lzdGVtX2NhODE5ZDg4LWYyYjAtNDczOC1hNDY4LTlhMDlmZGUyZWUyMyJ9.gORuVDx5G7DXN5eE07ePzzpSNBT0UNWKAKXSqlrNEDsDVzGys95GP9KCpjfns-f4HPKxO9YvLdt5gepIJKF1RWhn95o2ZPJBB2L0U8waMqk4y9U0_8HfAER5vZRAIWxvFAgGAM_KzRiPgg2GWfZZxqcO-Vse4zzy-ql4mV3vZaXvwu6HlBNkrRkC3sYp8QfwnoIRXuHVRRikbQZomdwpXiXX5MavhYqt_MFMMoiiXEiMBibXfM3HVk6dlEi1_qYon194UkfhTZRbyRBLYdz7ZpMv5vdWCWdL8iEqPL-FqsiXW-cXdlO0ZRvYhXkr4hZUyDKQiDEyW-K1L2QLBymtRw&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230505T182003Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAUUL327AZ6BSI6WPS%2F20230505%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=e1092f14545eb7fa6b2bb1ae8919c90f2a27dbd87c432925f0179abff108de9d')
        }

    }

    getUploadFileUrl(filename: string) {
        if (this.authConfig.secureToken) {
            return this.remoteGateway.doPut('files/' + filename, {});
        } else {
            return of({
                uploadS3Url: 'https://glm-migration.s3.amazonaws.com/GetLPSArtifacts_GDLNT114_20232711.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231206T160501Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Expires=600&X-Amz-Credential=AKIAUUL327AZ63LIFYWG%2F20231206%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=b8bc09752bbcb046b5eb9269d60466cdfaab1dfa793209c71c7da8caeba7c4bc'
            })
        }
    }

    logout() {
        if (this.authConfig.secureToken) {
            // notify the server to log the user out
            return this.remoteGateway.doPost('logout', {});
        } else {
            return of('good');
        }
    }
}