import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.css']
})
export class WelcomePageComponent implements OnInit {

  doNotShowWelcomePage: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (localStorage) {
      const doNotShowWelcomePage = localStorage.getItem('doNotShowWelcomePage');
      if (doNotShowWelcomePage) {
        this.router.navigate(['/migrate']);
      }
    } 
  }

  public startArtifactConversion() {
    this.router.navigate(['/migrate']);
  }

  public doNotShowAgainClicked(event: any) {
    if (localStorage) {
      if (this.doNotShowWelcomePage) {
        localStorage.setItem('doNotShowWelcomePage', 'true');
      } else {
        localStorage.removeItem('doNotShowWelcomePage');
      }
    }
  }
}
