import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_CONFIG, AppConfig } from 'src/app/app.config';
import { AUTH_CONFIG, AuthConfig } from 'src/app/auth.config';
import { AuthConstants } from 'src/app/constants/auth.constants';

@Component({
  selector: 'app-post-signup-page',
  templateUrl: './post-signup-page.component.html',
  styleUrls: ['./post-signup-page.component.css']
})
export class PostSignupPageComponent implements OnInit {

  accessCode: string;
  refreshToken: string;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.accessCode = params['code'];
        this.getTokens();
      }
      );
  }

  getTokens() {
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    const body = {}
    let httpParams = new HttpParams()
      .append('grant_type', 'authorization_code')
      .append('client_id', this.appConfig.cognitoClientId)
      .append('code', this.accessCode)
      .append('redirect_uri', window.location.protocol + '//' + window.location.host + '/wait');
    this.http.post<any>(this.appConfig.cognitoUrl + '/oauth2/token', body, { headers: headers, params: httpParams }).subscribe(data => {
      this.authConfig.secureToken = data[AuthConstants.ACCESS_TOKEN];
      this.refreshToken = data['refresh_token'];
      this.getUserInfo();
    })
  }

  getUserInfo() {
    const headers = { 'Authorization': 'Bearer ' + this.authConfig.secureToken }
    this.http.get<any>(this.appConfig.cognitoUrl + '/oauth2/userInfo', { headers }).subscribe(data => {

      if (data['custom:company2'] == null || data['custom:company2'] == undefined) {
        this.processUser(data['email']);
      }
      else {
        this.router.navigate([''], { queryParams: { access_token: this.authConfig.secureToken } });
      }
    })
  }

  processUser(userEmail: string) {
    const headers = { 'Authorization': 'Bearer ' + this.authConfig.secureToken }
    const body = { email: userEmail };
    this.http.post<any>(this.appConfig.serverUrl + '/' + this.appConfig.endpoint + '/processuser', body, { headers }).subscribe(data => {
      this.refreshTokens();
    })
  }

  refreshTokens() {

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    const body = {}
    let httpParams = new HttpParams()
      .append('grant_type', 'refresh_token')
      .append('client_id', this.appConfig.cognitoClientId)
      .append('refresh_token', this.refreshToken)
      .append('redirect_uri', window.location.protocol + '//' + window.location.host + '/wait');
    this.http.post<any>(this.appConfig.cognitoUrl + '/oauth2/token', body, { headers: headers, params: httpParams }).subscribe(data => {
      this.authConfig.secureToken = data[AuthConstants.ACCESS_TOKEN];
      this.router.navigate(['']);

    })
  }

}
