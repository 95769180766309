import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  sourceType: string
  glmDestinationType: string;
  targetSpectrumVersion: string;
  subProjectId: string;
  migrationArgs: string;
}

interface SpectrumVersion {
  value: string;
  label: string;
}

// # TODO: Create a new folder under components/convertDialog
@Component({
  selector: 'convert-dialog',
  templateUrl: './convert-dialog.component.html',
  styleUrls: ['./convert-dialog.component.css']

})
export class ConvertDialogComponent {

  spectrumVersions: SpectrumVersion[] = [
    { value: '4.7.1', label: '4.7.1' },
    { value: '5.0', label: '5.0' },
    { value: '5.1', label: '5.1' },
  ];
  @Input() supportGLM = false;
  @Input() showAdditionalProperties = false;
  constructor(public dialogRef: MatDialogRef<ConvertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  // Send the cancel message if the cancel button is closed
  onCancel() {
    this.dialogRef.close('cancel');
  }

  onConvert() {
    this.dialogRef.close(this.data)
  }

  filterSubProjectId(e: any) {
    const invalidCharsRegExp: RegExp = /[^\w]/gi;
    const t: EventTarget = e.target;
    if (t instanceof HTMLInputElement) {
      t.value = t.value.replace(invalidCharsRegExp, '');
    }
    this.data.subProjectId = this.data.subProjectId.replace(invalidCharsRegExp, '');
  }

}