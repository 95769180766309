<h1 mat-dialog-title>Convert</h1>
<div mat-dialog-content style="height: calc(100% - 96px);">
  <div *ngIf="supportGLM">
    <label id="source-file-type-label">Source Label Type</label>
    <mat-radio-group aria-label="Source File Type" id="sourceType" class="radio-group">
      <mat-radio-button value="LPS" name="lpsRadio" class="radio-button" ngDefaultControl
        [(ngModel)]="data.sourceType">LPS</mat-radio-button>
      <mat-radio-button value="RTF" name="glmRadio" class="radio-button" ngDefaultControl
        [(ngModel)]="data.sourceType">GLM</mat-radio-button>
    </mat-radio-group>
  </div>
  <div *ngIf="data.sourceType =='RTF'">
    <label id="target-labeling-solution-label">Target Labeling Solution</label>
    <mat-radio-group aria-label="Select an option" class="radio-group">
      <mat-radio-button value="ser" name="serRadio" class="radio-button" ngDefaultControl
        [(ngModel)]="data.glmDestinationType">Spectrum
        (.ser)</mat-radio-button>
      <mat-radio-button value="nlbl" name="nlblRadio" class="radio-button" ngDefaultControl
        [(ngModel)]="data.glmDestinationType">NiceLabel
        (.nlbl)</mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="data.sourceType =='LPS'">
    <mat-form-field>
      <mat-label>Target Spectrum Version</mat-label>
      <mat-select [(ngModel)]="data.targetSpectrumVersion">
        <mat-option *ngFor="let version of spectrumVersions" [value]="version.value">{{version.label}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="data.sourceType =='LPS'">
    <mat-form-field>
      <mat-label>Sub Project ID</mat-label>
      <input matInput placeholder="" [(ngModel)]="data.subProjectId" (input)="filterSubProjectId($event)" maxlength="50">
    </mat-form-field>
  </div>
  <div *ngIf="data.sourceType =='LPS' && showAdditionalProperties">
    <mat-form-field>
      <mat-label>Additional Properties</mat-label>
      <input matInput placeholder="" [(ngModel)]="data.migrationArgs">
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close (click)="onConvert()">Convert</button>
  <button mat-raised-button mat-dialog-close (click)="onCancel()">Cancel</button>

</div>