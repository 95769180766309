<div>
    <header></header>
    <div class="help-message-block">
        <div class="message-heading">Convert LPS artifacts into Spectrum objects</div>
        <div class="help-message"> Click “Learn more” for how to use the LPS Migration Portal, how to prepare for a
            migration and what to expect with converted output.
        </div>
        <div class="help-button">
            <a id="learnMore" class="lwInterfaceButton" href="https://docs.loftware.com/LPSMigrationPortal/Default.htm"
                target="_blank" role="button">Learn more</a>
        </div>
    </div>


    <div class="mx-auto mt-4" style="width: 1000px;">
        <div class="mt-2" style="max-height:100px">
            <div class="card card-body shadow bg-white rounded mt-2">
                <div class="row">
                    <main role="main">
                        <div class="card card-body shadow bg-white rounded">
                            <div class="stepTitle"><strong>Step 1 - </strong>Download the GetLPSArtifacts script</div>
                            <p>The GetLPSArtifacts script is a utility that will read an LPS system’s
                                configuration and copy artifacts to a single zip file for conversion. Artifacts are
                                files
                                that are necessary for running LPS including, but not limited to, label templates, image
                                files, layout files, printer configuration, and system configuration.
                            </p>
                            <div>
                                <div class="float-start">
                                    <button type="button" class="lwInterfaceButton" (click)="onLpsArtifactsClick()">
                                        Download GetLPSArtifacts script
                                    </button>
                                </div>
                                <div class="help-link">
                                    <a
                                        href="https://docs.loftware.com/LPSMigrationPortal/Portal/Migrate/TskB-GatherLPSArtifacts.htm?tocpath=Migrate%20LPS%20Artifacts%20to%20Spectrum%7CB.%20Gather%20LPS%20Artifacts%20for%20Conversion%7C_____0">
                                        More info on the GetLPSArtifacts Script
                                    </a>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <div class="card card-body shadow bg-white rounded mt-2">
                <div class="row">
                    <main role="main">
                        <div class="card card-body shadow bg-white rounded">
                            <div class="stepTitle"><strong>Step 2 - </strong>Run the GetLPSArtifacts script on
                                your production LPS servers</div>
                            <p>Run the GetLPSArtifacts script on the LPS server that needs to be migrated
                                to Spectrum.</p>
                            <div>
                                <div class="help-link">
                                    <a
                                        href="https://docs.loftware.com/LPSMigrationPortal/Portal/Migrate/TskB-GatherLPSArtifacts.htm?tocpath=Migrate%20LPS%20Artifacts%20to%20Spectrum%7CB.%20Gather%20LPS%20Artifacts%20for%20Conversion%7C_____0">
                                        How to run the GetLPSArtifacts Script
                                    </a>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <div class="card card-body shadow bg-white rounded mt-2">
                <div class="row">
                    <main role="main">
                        <div class="card card-body shadow bg-white rounded">
                            <div class="stepTitle"><strong>Step 3 - </strong>Upload the GetLPSArtifact zip files
                                produced by the
                                GetLPSArtifacts script for conversion</div>
                            <p>Upload the zip file of LPS artifacts produced by the GetLPSArtifacts
                                script
                                or created
                                manually to convert contents into Spectrum objects.</p>
                            <div class="input-group mb-3">
                                <span class="input-group-text" id="inputGroupPrepend1"><label class="pt-1"
                                        style="height: 15px;">*</label></span>
                                <input #fileInput type="file" class="form-control" id="customFile"
                                    accept="zip,application/zip,application/x-zip,application/x-zip-compressed"
                                    ng2FileSelect [uploader]="uploader" />
                            </div>
                            <div>
                                <div class="float-start">
                                    <button type="button" class="lwInterfaceButton" (click)="onUploadClick()"
                                        [disabled]="!uploader.getNotUploadedItems()?.length || !isValid ">
                                        <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>&nbsp;Upload LPS artifacts
                                    </button>
                                </div>

                                <div class="help-link">
                                    <a
                                        href="https://docs.loftware.com/LPSMigrationPortal/Portal/Migrate/TskC-UploadLPSArtifacts.htm?tocpath=Migrate%20LPS%20Artifacts%20to%20Spectrum%7C_____3">
                                        How to upload files for conversion
                                    </a>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <div class="card card-body shadow bg-white rounded mt-2">
                <div class="row">
                    <main role="main">
                        <div class="card card-body shadow bg-white rounded">
                            <div class="stepTitle"><strong>Step 4 - </strong>Submit uploaded GetLPSArtifact zip files
                                for conversion</div>
                            <p>After your file is uploaded, it is displayed in this table.<br />
                                Click the Convert link for your file to convert your LPS artifacts into Spectrum
                                objects.
                            </p>
                            <div class="mb-5">
                                <div class="help-link">
                                    <a
                                        href="https://docs.loftware.com/LPSMigrationPortal/Portal/Migrate/TskD-ConvertLPSArtifacts.htm?tocpath=Migrate%20LPS%20Artifacts%20to%20Spectrum%7C_____4">
                                        More about artifact conversion
                                    </a>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <!-- <kendo-grid id="tableConvert" class="lwUniformBranding" [kendoGridBinding]="uploadedFiles"
                                    [sortable]="true">
                                    <kendo-grid-column [width]="450" field="filename" title="File Name"
                                    [headerClass]="'lwUniformBranding'">
                                    </kendo-grid-column>
                                    <kendo-grid-column [width]="200" field="fileSizeDisplay" title="File Size">
                                    </kendo-grid-column>
                                    <kendo-grid-column [width]="300" field="createDate"
                                        title="Created Date"></kendo-grid-column>
                                    <kendo-grid-column [width]="100">
                                        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-file>
                                            <label *ngIf="!file.loading" style="cursor: pointer;"
                                                class="border-bottom text-primary"
                                                (click)="onConvertClick(file)">Convert</label>
                                            <div *ngIf="file.loading" class="mr-3 spinner-border spinner-border-sm"
                                                role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </ng-template>
                                    </kendo-grid-column>
                                </kendo-grid>
                                <br> -->
                                <table class="table table-hover table-sm">
                                    <thead class="table-white">
                                        <tr>
                                            <th (click)="sortTable(tableUploaded,'filename')" class="sortable-column"
                                                title="{{tableUploaded.fileNameDirection}}">
                                                File Name
                                                <span
                                                    [ngClass]="{'arrow-up': tableUploaded.fileNameDirection === 'asc', 'arrow-down': tableUploaded.fileNameDirection === 'desc'}"></span>
                                            </th>
                                            <th (click)="sortTable(tableUploaded,'fileSize')" class="sortable-column"
                                                title="{{tableUploaded.fileSizeDirection}}">
                                                File Size
                                                <span
                                                    [ngClass]="{'arrow-up': tableUploaded.fileSizeDirection === 'asc', 'arrow-down': tableUploaded.fileSizeDirection=== 'desc'}"></span>
                                            </th>
                                            <th (click)="sortTable(tableUploaded,'createDate')" class="sortable-column"
                                                title="{{tableUploaded.createDateDirection}}">
                                                Created Date
                                                <span
                                                    [ngClass]="{'arrow-up': tableUploaded.createDateDirection === 'asc', 'arrow-down': tableUploaded.createDateDirection === 'desc'}"></span>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let file of uploadedFiles">
                                            <td>{{ file.filename }}</td>
                                            <td>{{ file.fileSizeDisplay }}</td>
                                            <td>{{ file.createDateDisplay }}</td>
                                            <td class="text-right">
                                                <label *ngIf="!file.loading  && !file.deleting" style="cursor: pointer;"
                                                    class="border-bottom text-primary"
                                                    (click)="onConvertClick(file)">Convert</label>
                                                <div *ngIf="file.loading" class="mr-3 spinner-border spinner-border-sm"
                                                    role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <i *ngIf="!file.deleting" class="ml-3 bi bi-trash3 clickable"
                                                    (click)="confirmDelete(file)"></i>
                                                <div *ngIf="file.deleting" class="ml-3 spinner-border spinner-border-sm"
                                                    role="status">
                                                    <span class="sr-only">Deleting...</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="uploadedFiles?.length === 0">
                                            <td colspan="4">File list is empty.</td>
                                        </tr>
                                    </tbody>
                                </table>


                            </div>
                        </div>

                        <div class="alert alert-danger" role="alert" *ngIf="httpError">
                            {{httpError?.message}}
                        </div>
                    </main>
                </div>
            </div>

            <div class="card card-body shadow bg-white rounded mt-2">
                <div class="row">
                    <main role="main">
                        <div class="card card-body shadow bg-white rounded">
                            <div class="stepTitle">Conversions in progress</div>
                            <p>You will receive an email when the conversion process is complete.</p>
                            <div class="table-responsive">
                                <table class="table table-hover table-sm">
                                    <thead class="table-white">
                                        <tr>
                                            <th (click)="sortTable(tableConverting,'filename')" class="sortable-column"
                                                title="{{tableConverting.fileNameDirection}}">
                                                File Name
                                                <span
                                                    [ngClass]="{'arrow-up': tableConverting.fileNameDirection === 'asc', 'arrow-down': tableConverting.fileNameDirection === 'desc'}"></span>
                                            </th>
                                            <th (click)="sortTable(tableConverting,'statusDetail')"
                                                class="sortable-column" title="{{tableConverting.statusDirection}}">
                                                Status
                                                <span
                                                    [ngClass]="{'arrow-up': tableConverting.statusDirection === 'asc', 'arrow-down': tableConverting.statusDirection === 'desc'}"></span>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let file of convertingFiles;">
                                            <td class="pt-3">{{file.filename}}</td>
                                            <td *ngIf="file.statusDetail!=null">{{file.statusDetail}}</td>
                                            <td *ngIf="file.statusDetail==null">
                                                <div class="spinner-border spinner-border-sm mr-3" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <div *ngIf="convertingFiles?.length === 0">File list is empty.</div>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="alert alert-danger" role="alert" *ngIf="httpError">
                            {{httpError?.message}}
                        </div>
                    </main>
                </div>
            </div>
            <div class="card card-body shadow bg-white rounded mt-2">
                <div class="row">
                    <main role="main">
                        <div class="card card-body shadow bg-white rounded">

                            <div class="stepTitle"><strong>Step 5 - </strong>Download the converted files produced once
                                the conversion
                                process
                                has completed</div>

                            <div class="mb-5">
                                <div class="help-link">
                                    <a
                                        href="https://docs.loftware.com/LPSMigrationPortal/Portal/Migrate/TskE-DownloadConvertedObjects.htm?tocpath=Migrate%20LPS%20Artifacts%20to%20Spectrum%7C_____5">
                                        How to download converted artifacts
                                    </a>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <table class="table table-hover table-sm">
                                    <thead class="table-white">
                                        <tr>
                                            <th (click)="sortTable(tableConverted,'filename')" class="sortable-column"
                                                title="{{tableConverted.fileNameDirection}}">
                                                File Name
                                                <span
                                                    [ngClass]="{'arrow-up': tableConverted.fileNameDirection === 'asc', 'arrow-down': tableConverted.fileNameDirection === 'desc'}"></span>
                                            </th>
                                            <th (click)="sortTable(tableConverted,'fileSize')" class="sortable-column"
                                                title="{{tableConverted.fileSizeDirection}}">
                                                File Size
                                                <span
                                                    [ngClass]="{'arrow-up': tableConverted.fileSizeDirection === 'asc', 'arrow-down': tableConverted.fileSizeDirection === 'desc'}"></span>
                                            </th>
                                            <th (click)="sortTable(tableConverted,'createDate')" class="sortable-column"
                                                title="{{tableConverted.createDateDirection}}">
                                                Created Date
                                                <span
                                                    [ngClass]="{'arrow-up': tableConverted.createDateDirection === 'asc', 'arrow-down': tableConverted.createDateDirection === 'desc'}"></span>
                                            </th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let file of convertedFiles">

                                            <td class="pt-3" [ngClass]="{error : file.errored }">
                                                {{file.filename}}</td>
                                            <td class="pt-3" [ngClass]="{error : file.errored }">
                                                {{file.fileSizeDisplay}}</td>
                                            <td class="pt-3" [ngClass]="{error : file.errored }">
                                                {{file.createDateDisplay}}</td>
                                            <td class="pt-3 text-right">
                                                <label *ngIf="file.compareAvailable && !file.deleting && !file.errored"
                                                    style="cursor: pointer;margin-right:3em;"
                                                    class="border-bottom text-primary"
                                                    (click)="onCompareClick(file)">Compare</label>
                                            </td>
                                            <td class="pt-3 text-right">
                                                <label *ngIf="!file.deleting" style="cursor: pointer;"
                                                    class="border-bottom text-primary"
                                                    (click)="onDownloadClick(file)">Download</label>
                                            </td>
                                            <td class="pt-3 text-right">
                                                <i *ngIf="!file.deleting" class="ml-3 bi bi-trash3 clickable"
                                                    (click)="confirmDelete(file)"></i>
                                                <div *ngIf="file.deleting" class="ml-3 spinner-border spinner-border-sm"
                                                    role="status">
                                                    <span class="sr-only">Deleting...</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <div *ngIf="convertedFiles?.length === 0">File list is empty.</div>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="alert alert-danger" role="alert" *ngIf="httpError">
                            {{httpError?.message}}
                        </div>
                    </main>
                </div>
            </div>
            <div class="card card-body shadow bg-white rounded mt-2">
                <div class="row">
                    <main role="main">
                        <div class="card card-body shadow bg-white rounded">
                            <div class="stepTitle"><strong>Step 6 - </strong>Use the Spectrum Transfer Utility to import
                                the converted
                                artifacts to your Spectrum system</div>
                            <p>The LPS artifacts submitted for conversion are now ready to be imported into
                                Spectrum.</p>

                            <div class="mb-4">
                                <div class="help-link">
                                    <a
                                        href="https://docs.loftware.com/LPSMigrationPortal/Portal/Migrate/TskF-ImportObjects.htm?tocpath=Migrate%20LPS%20Artifacts%20to%20Spectrum%7C_____6">
                                        How to import converted artifacts into Spectrum
                                    </a>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>